
import ApiService from "@/core/services/ApiService";
interface IPassword {
    old_password: string;
    new_password: string;
    con_password: string;
    user_id: string;
}

export { IPassword };
export const changePasswordUser = async (payload) => {
    const response = await ApiService.post("/user/change-password", { data: payload });
    return response;
};