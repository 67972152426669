
import { defineComponent, onMounted, ref, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { changePasswordUser, IPassword } from "./profile";
import * as Yup from "yup";
import { useStore } from "vuex";

export default defineComponent({
  name: "account-settings",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters.currentUser.data);
    const updatePasswordButton = ref<HTMLElement | null>(null);
    const emailFormDisplay = ref(false);
    const passwordFormDisplay = ref(false);
    const UserForm = ref<IPassword>({
      old_password: "",
      new_password: "",
      con_password: "",
      user_id: user.value._id,
    });
    const changePassword = Yup.object().shape({
      currentpassword: Yup.string().required().label("Current password"),
      newpassword: Yup.string().min(4).required().label("Password"),
      confirmpassword: Yup.string()
        .min(4)
        .required()
        .oneOf([Yup.ref("newpassword"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    const updatePassword = async () => {
      if (updatePasswordButton.value) {
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");
        const playlaod = UserForm.value;
        await changePasswordUser(playlaod)
          .then((resp) => {
            if (resp.status == 200) {
              Swal.fire({
                text: "Password telah di perbaharui!",
                icon: "success",
                confirmButtonText: "Ok",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-light-primary",
                },
              }).then(() => {
                UserForm.value = {
                  old_password: "",
                  new_password: "",
                  con_password: "",
                  user_id: user.value._id,
                };
                passwordFormDisplay.value = false;
              });
            } else {
              Swal.fire({
                text: "Gagal Menyimpan!",
                icon: "error",
                confirmButtonText: "Ok",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-light-primary",
                },
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              text: error.response.data.detail,
              icon: "error",
              confirmButtonText: "Ok",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-light-primary",
              },
            });
          });
        updatePasswordButton.value.removeAttribute("data-kt-indicator");
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Settings", ["Account"]);
    });

    return {
      emailFormDisplay,
      passwordFormDisplay,
      changePassword,
      updatePasswordButton,
      updatePassword,
      store,
      user,
      UserForm,
    };
  },
});
